<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-contact class="w-full" :iscustomer="true" name="customer_id" label="Customer" v-model="filterData.customer_id" v-on:onChangeContact="changeCustomer"></browse-contact>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <small class="ml-2">Invoice Date</small>
                <div class='flex align-items-center'>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.invoice_date_start" @input='getData()'></flat-pickr>
                    <small class='ml-5 mr-5'>Until</small>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.invoice_date_end"  @input='getData()'></flat-pickr>
                </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <small class="ml-2">Due Date</small>
                <div class='flex align-items-center'>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.due_date_start" @input='getData()'></flat-pickr>
                    <small class='ml-5 mr-5'>Until</small>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.due_date_end"  @input='getData()'></flat-pickr>
                </div>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Invoice Customer">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="success" class='mr-1 ml-1' @click="is_filter = !is_filter">Filter</vs-button>&nbsp;
            <vs-button color="primary" :to="{name: 'invoices-create'}">Add New</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="id">Invoice No.</vs-th>
            <vs-th sort-key="bill_name">Customer Bills</vs-th>
            <vs-th sort-key="date_transaction">Invoice Date</vs-th>
            <vs-th sort-key="due_date">Due Date</vs-th>
            <vs-th sort-key="grand_total">Grand Total</vs-th>
            <vs-th sort-key="grand_total">Status</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.id">
                <router-link :to="{name:'invoices-show', params:{id: tr.id}}">{{tr.code}}</router-link>
              </vs-td>
              <vs-td label="Customer Bills" :data="tr.bill_name">{{tr.bill_name}}</vs-td>
              <vs-td label="Invoice Date" :data="tr.date_transaction">{{tr.date_transaction|moment('Do MMM YYYY')}}</vs-td>
              <vs-td label="Due Date" :data="tr.due_date">{{tr.due_date|moment('Do MMM YYYY')}}</vs-td>
              <vs-td label="Grand Total" :data="tr.grand_total">{{tr.grand_total|numFormat}}</vs-td>
              <vs-td :data="tr.status">
                <vs-chip v-if="tr.status==1" color="warning">DRAFT</vs-chip>
                <vs-chip v-if="tr.status==2" color="success">APPROVED</vs-chip>
                <vs-chip v-if="tr.status==3" color="primary">PAID</vs-chip>
              </vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button v-if="tr.status==1" size="small" type="line" color='success' :to="{name:'invoices-edit', params:{id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button size="small" type="line" :to="{name:'invoices-show', params:{id: tr.id}}" icon-pack="feather" icon="icon-folder"></vs-button>
                  <vs-button v-if="tr.status==1" color='danger' size="small" type="line" @click="deletes(tr.id)" icon-pack="feather" icon="icon-trash"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import BrowseContact from '@browse/Contact.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components:{
    BrowseContact,
    flatPickr
  },
  data(){
    return {
      filterData : {},
      is_filter : false,
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      flatPickrConfig: {
            altFormat: 'j M Y',
            altInput: true,
            dateFormat: 'Y-m-d',
      }
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.invoices.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'invoices/index',
      dispatchDestroy: 'invoices/destroy'
    }),
    async getData(){
      let payload = {
        orderBy : 'c.date_transaction',
        sortingBy : 'desc',
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      payload = Object.assign(payload, this.filterData)
      await this.dispatchIndex(payload)
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async changeCustomer(data){
      this.filterData.customer_id = data.id
      await this.getData();
    },
    async resetFilter() {
      this.filterData = {}
      await this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>